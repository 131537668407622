import React from "react";
import Img from "gatsby-image";
import { Row, Col } from "react-styled-flexboxgrid";
import { HrefButton } from "../Button";
import { Primary } from "../../components/Section";
import { FashionGallery } from "../Fashion";

const Shop = ({ data }) => {
  return (
    <section>
      <Primary>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={12} md={6}>
                <Img fluid={data.polo.childImageSharp.fluid} />
              </Col>
              <Col xs={12} md={6}>
                <h3>MJMD Global Golf Polo</h3>
                <h4>$65.00</h4>
                <HrefButton
                  href="https://bly-management-lp.square.site/product/mjmd-global-polo/2?cs=true"
                  target="_blank"
                >
                  Shop Now
                </HrefButton>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Img fluid={data.hat.childImageSharp.fluid} />
              </Col>
              <Col xs={12} md={6}>
                <h3>MJMD Global Hat</h3>
                <h4>$29.00 </h4>
                <HrefButton
                  href="https://bly-management-lp.square.site/product/mjmd-global-hat/1?cs=true"
                  target="_blank"
                >
                  Shop Now
                </HrefButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Primary>
      <FashionGallery />
    </section>
  );
};

export default Shop;
