import React from "react";
import Layout from "../components/Layout";

import Shop from "../components/Shop";

const ShopPage = ({ data }) => (
  <Layout>
    <Shop data={data} />
  </Layout>
);

export default ShopPage;

export const query = graphql`
  query {
    polo: file(relativePath: { eq: "polo-black.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hat: file(relativePath: { eq: "gallery-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
