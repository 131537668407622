import React from "react";
import Gallery from "react-photo-gallery";

import G01 from "../../images/gallery-01.jpg";
import G02 from "../../images/gallery-02.jpg";
import G03 from "../../images/gallery-03.jpg";
import G04 from "../../images/gallery-04.jpg";
import G05 from "../../images/gallery-05.jpg";
import G06 from "../../images/gallery-06.jpg";
import G07 from "../../images/gallery-07.jpg";
import G08 from "../../images/gallery-08.jpg";

const photos = [
  {
    src: G01,
    width: 14,
    height: 14
  },
  {
    src: G02,
    width: 14,
    height: 25
  },
  {
    src: G03,
    width: 30.2,
    height: 35.6
  },
  {
    src: G04,
    width: 23.2,
    height: 30.9
  },
  {
    src: G05,
    width: 23.5,
    height: 26.8
  },
  {
    src: G06,
    width: 29.6,
    height: 33.5
  },
  {
    src: G07,
    width: 30.2,
    height: 40.3
  },
  {
    src: G08,
    width: 48,
    height: 63
  }
];

const FashionGallery = () => {
  return <Gallery photos={photos} />;
};

export default FashionGallery;
