import React from "react";
import Img from "gatsby-image";
import { Link, navigate } from "gatsby";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { styleBackgroundImage } from "../../components/Section/section.style";
import { Row, Col } from "react-styled-flexboxgrid";
import Slider from "react-styled-carousel";
import { Primary, Alternate } from "../../components/Section";
import { ButtonGhost } from "../../components/Button";
import FashionGallery from "./FashionGallery";
import SliderLaughterSmoke from "../../images/slider-laughter-smoke.jpg";
import SliderCufflinks from "../../images/slider-cufflinks.jpg";
import SliderCupGlasses from "../../images/slider-drink-glasses.jpg";
import SliderCigar from "../../images/slider-cigar.jpg";
import SliderGroupMen from "../../images/slider-goup-men.jpg";

import Logo from "../../images/logo.svg";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-travel-forward.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
  background-position-y: 15%;
`;
const StyledLink = styled(Link)`
  color: #6cbb40;
  &:hover {
    text-decoration: none;
  }
`;

const SliderImage = styled.img`
  width: 100%;
  margin: 0;
  padding: 0;
`;
const StyledLogo = styled.img`
  margin: 2rem;
  width: 10rem;
`;
const settings = {
  cardsToShow: 1,
  showArrows: false,
  padding: 0,
  margin: 0,
};
const Fashion = ({ data }) => (
  <section>
    <StyledBackgroundSection centered>
      <Row>
        <Col xs={12}>
          <h1>Fashion & Style</h1>
          <p>
            Remember the Rat Pack and the Harlem Renaissance … The ultimate
            period of VIP Fashion & Style. MJMD Global Group promotes “MJ Black”
            Formal Attire and MJ Global Golf Gear.
          </p>
        </Col>
      </Row>
    </StyledBackgroundSection>
    <Alternate centered>
      <h2>The Logo: Meaning</h2>
      <StyledLogo src={Logo} alt="MJ Global Logo" />
      <p>
        In the middle is a “stake” as a stake that you place in the ground..
        meaning it marks an area that you claim as ownership and the top of the
        logo shows protection. Therefore, when you wear MJ Global you are
        claiming your ownership and protecting it. Claim and protect your
        family, health, wealth and more! MJ Global represents U!
      </p>
    </Alternate>
    <Primary centered>
      <Row>
        <Col xs={12}>
          <h2>
            “Fashion is a part of style, Style is a part of Freedom, Freedom is
            a part of Life. Life is what we live for.” MJ
          </h2>
        </Col>
      </Row>
    </Primary>
    <Slider {...settings}>
      <SliderImage src={SliderLaughterSmoke} alt="Men laughing and smoking" />
      <SliderImage src={SliderCufflinks} alt="cufflinks of MJ Global logo" />
      <SliderImage src={SliderCupGlasses} alt="whiskey glasses" />
      <SliderImage src={SliderCigar} alt="cigar being lit" />
      <SliderImage src={SliderGroupMen} alt="group of men" />
    </Slider>
    <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Go Touchless with Global Fashion</h2>
          <blockquote>
            “The most important shot in golf is the next one.” - Ben Hogan
          </blockquote>
          <p>
            MJ Black and MJ Golf Gear embrace the theme of{" "}
            <strong>“Creating New History!”</strong> Shop our gear and find your
            style.
          </p>
          <p>
            <StyledLink to="/reach-us">Contact us</StyledLink> for bulk orders
            and consignment opportunities in stores.
          </p>
          <ButtonGhost onClick={() => navigate("/shop")}>Read More</ButtonGhost>
        </Col>
        <Col xs={12} md={6}>
          <Img
            fluid={data.golffashion.childImageSharp.fluid}
            alt="MJ Cuff Links"
          />
        </Col>
      </Row>
    </Alternate>
    <FashionGallery />
  </section>
);

export default Fashion;
